<template>
    <div>
        <modal height="auto" :name="modal_name" class="final-modal" transition="nice-modal-fade">
            <div class="v-modal-content p-0">
                <div class="v-modal-header v-modal-header-new-back" style="background-color:#00448b !important">
                    <span class="v-modal-dialog-title v-modal-title fw-500 p-3 m-auto" style="color: #fff !important;letter-spacing:1px;">TERMINATE EMPLOYEE</span>
                    <button  type="button" class="pointer" style="background-color: #00448b !important;outline:none;" aria-label="Close" @click="hideTerminateEmployee()">
                        <span aria-hidden="true">
                            <i class="icon icon-close" style="color:white;margin:10px 10px 0 0;"></i>
                        </span>
                    </button>
                </div>
                <div class="m-4">
                    <div class="v-modal-body pt-0 pb-0 my-7" style="max-height:430px;overflow-y:auto;overflow-x:auto;">
                        <p class="text-center" style="margin:0 auto;width:100%">Are You Sure You Want To Terminate <span class="fw-600 text-capitalize">{{employee_name}}</span> Employee</p>
                    </div>
                    <div class="v-modal-dialog-actions text-center my-7">
                        <a class="btn btn-danger btn-smm text-white mr-2" style="text-transform: uppercase !important;font-size: 12px !important;border-radius:5px !important;" @click="hideTerminateEmployee()">Cancel</a>
                        <a class="btn btn-secondary btn-smm text-white" style="text-transform: uppercase !important;font-size: 12px !important;border-radius:5px !important;" @click="terminateEmployeeSuccess()">OK</a>
                    </div> 
                </div>     
            </div>
        </modal>
    </div>
</template>

<script>

export default ({
    props:['modal_name', 'employee_id', 'employee_name'],
    data(){
        return{

        }
    },
    methods: {
        hideTerminateEmployee(){
            this.$emit('hide-terminate-employee');
        },
        terminateEmployeeSuccess() {
            this.$emit('terminate-employee-success', this.employee_id);
        }
    },
})

</script>

<style scoped>
    .vm--modal{
        min-width:100px !important;
    }
    .v-modal-header{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 20px;
    }
</style>